@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?9a9l39');
  src:  url('../fonts/icomoon.eot?9a9l39#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?9a9l39') format('truetype'),
    url('../fonts/icomoon.woff?9a9l39') format('woff'),
    url('../fonts/icomoon.svg?9a9l39#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-soundcloud:before {
  content: "\e96a";
  color: #f30;
}
.icon-eco:before {
  content: "\e962";
}
.icon-qr:before {
  content: "\e963";
}
.icon-nfc:before {
  content: "\e964";
}
.icon-endless:before {
  content: "\e965";
}
.icon-diamond:before {
  content: "\e966";
}
.icon-steel:before {
  content: "\e967";
}
.icon-updatable:before {
  content: "\e968";
}
.icon-laser:before {
  content: "\e969";
}
.icon-solo:before {
  content: "\e961";
}
.icon-grid-square:before {
  content: "\e959";
}
.icon-grid-wide:before {
  content: "\e95a";
}
.icon-grid-wider:before {
  content: "\e95b";
}
.icon-500px:before {
  content: "\e903";
  color: #0099e5;
}
.icon-behance:before {
  content: "\e904";
  color: #1769ff;
}
.icon-bitbucket:before {
  content: "\e90c";
  color: #0052cc;
}
.icon-deviantart:before {
  content: "\e91f";
  color: #05cc47;
}
.icon-discord:before {
  content: "\e926";
  color: #7289da;
}
.icon-dribbble:before {
  content: "\e927";
  color: #ea4c89;
}
.icon-facebook:before {
  content: "\e928";
  color: #1877f2;
}
.icon-flickr:before {
  content: "\e932";
  color: #0063dc;
}
.icon-github:before {
  content: "\e93c";
}
.icon-gitlab:before {
  content: "\e93d";
  color: #fca121;
}
.icon-hangouts:before {
  content: "\e93e";
  color: #0c9d58;
}
.icon-instagram:before {
  content: "\e941";
  color: #e4405f;
}
.icon-invision:before {
  content: "\e942";
  color: #f36;
}
.icon-kik:before {
  content: "\e943";
  color: #82bc23;
}
.icon-line:before {
  content: "\e944";
  color: #00c300;
}
.icon-linkedin:before {
  content: "\e945";
  color: #0077b5;
}
.icon-messenger:before {
  content: "\e947";
  color: #00b2ff;
}
.icon-pinterest:before {
  content: "\e948";
  color: #bd081c;
}
.icon-shopify:before {
  content: "\e949";
  color: #7ab55c;
}
.icon-skype:before {
  content: "\e94c";
  color: #00aff0;
}
.icon-snapchat:before {
  content: "\e94d";
  color: #fffc00;
}
.icon-telegram:before {
  content: "\e94e";
  color: #2ca5e0;
}
.icon-tiktok:before {
  content: "\e94f";
}
.icon-tumblr:before {
  content: "\e950";
  color: #36465d;
}
.icon-twitch:before {
  content: "\e951";
  color: #6441a4;
}
.icon-twitter:before {
  content: "\e952";
  color: #1da1f2;
}
.icon-viber:before {
  content: "\e953";
  color: #665cac;
}
.icon-vimeo:before {
  content: "\e954";
  color: #1ab7ea;
}
.icon-wechat:before {
  content: "\e955";
  color: #7bb32e;
}
.icon-whatsapp:before {
  content: "\e956";
  color: #25d366;
}
.icon-youtube:before {
  content: "\e957";
  color: #f00;
}
.icon-pen:before {
  content: "\e95d";
}
.icon-paintbrush:before {
  content: "\e036";
}
.icon-profile-male:before {
  content: "\e040";
}
.icon-avatar:before {
  content: "\e95e";
}
.icon-grid:before {
  content: "\e95c";
}
.icon-help-circle:before {
  content: "\e95f";
}
.icon-maximize:before {
  content: "\e960";
}
.icon-alert-circle:before {
  content: "\e900";
}
.icon-at-sign:before {
  content: "\e901";
}
.icon-book:before {
  content: "\e93a";
}
.icon-book-open:before {
  content: "\e93f";
}
.icon-briefcase:before {
  content: "\e94b";
}
.icon-camera:before {
  content: "\e902";
}
.icon-folder:before {
  content: "\e940";
}
.icon-globe:before {
  content: "\e905";
}
.icon-home:before {
  content: "\e906";
}
.icon-image:before {
  content: "\e907";
}
.icon-info:before {
  content: "\e908";
}
.icon-instagram-mono:before {
  content: "\e909";
}
.icon-link:before {
  content: "\e90a";
}
.icon-link-2:before {
  content: "\e90b";
}
.icon-lock:before {
  content: "\e90d";
}
.icon-log-in:before {
  content: "\e90e";
}
.icon-log-out:before {
  content: "\e90f";
}
.icon-mail:before {
  content: "\e910";
}
.icon-map-pin:before {
  content: "\e911";
}
.icon-menu:before {
  content: "\e912";
}
.icon-message-circle:before {
  content: "\e946";
}
.icon-message-square1:before {
  content: "\e913";
}
.icon-more-horizontal:before {
  content: "\e914";
}
.icon-more-vertical:before {
  content: "\e915";
}
.icon-move:before {
  content: "\e916";
}
.icon-navigation:before {
  content: "\e917";
}
.icon-navigation-2:before {
  content: "\e918";
}
.icon-paperclip:before {
  content: "\e919";
}
.icon-plus-circle:before {
  content: "\e91a";
}
.icon-plus-square:before {
  content: "\e91b";
}
.icon-refresh-cw:before {
  content: "\e91c";
}
.icon-rotate-cw:before {
  content: "\e91d";
}
.icon-search:before {
  content: "\e91e";
}
.icon-send:before {
  content: "\e93b";
}
.icon-settings:before {
  content: "\e920";
}
.icon-share-2:before {
  content: "\e958";
}
.icon-sliders:before {
  content: "\e921";
}
.icon-thumbs-down:before {
  content: "\e922";
}
.icon-thumbs-up:before {
  content: "\e923";
}
.icon-tool:before {
  content: "\e924";
}
.icon-trash-2:before {
  content: "\e925";
}
.icon-unlock:before {
  content: "\e929";
}
.icon-upload:before {
  content: "\e92a";
}
.icon-user:before {
  content: "\e92b";
}
.icon-user-check:before {
  content: "\e92c";
}
.icon-user-minus:before {
  content: "\e92d";
}
.icon-user-plus:before {
  content: "\e92e";
}
.icon-user-x:before {
  content: "\e92f";
}
.icon-x:before {
  content: "\e930";
}
.icon-x-square:before {
  content: "\e931";
}
.icon-arrow-right:before {
  content: "\e933";
}
.icon-arrow-left:before {
  content: "\e934";
}
.icon-arrow-down:before {
  content: "\e935";
}
.icon-arrow-up:before {
  content: "\e936";
}
.icon-chevron-left:before {
  content: "\e937";
}
.icon-phone:before {
  content: "\e938";
}
.icon-message-square:before {
  content: "\e939";
}
.icon-move-vertical:before {
  content: "\e94a";
}
.icon-arrows:before {
  content: "\e94a";
}
